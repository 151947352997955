import "./App.css";
import me from "./me.png";

function App() {
  return (
    <div className="App">
      <img
        className="background"
        src={me}
        alt="me"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div className="greeting">
        <h1>Hello, I'm Hang.</h1>
        <h1>Welcome to my page!</h1>
      </div>
    </div>
  );
}

export default App;
